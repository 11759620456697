<template>
  <div class="about">
    <h3>Selve dåben</h3>
    <p><a href="https://goo.gl/maps/rL17Zmt9q8oMLdoM9" target="_blank">Risskov Kirke, Solbakken 2, 8240 Risskov</a> kl. 12.</p>
    <h3>Reception</h3>
    <p><a href="https://goo.gl/maps/nagXGWpW5n6APe7z9" target="_blank">Themsvej 38A, 8240 Risskov</a> fra kl. 13.</p>
    <h3>Svar udbedes</h3>
    <p>Inden 14. juni.</p>
    <p>Jesper: <a href="tel:51942537">51942537</a></p>
    <p>Sarah: <a href="tel:61263802">61263802</a></p>
  </div>
</template>
